<template>
  <main class="auth-tracking">
    <h1>
      Auth Tracking
    </h1>

    <div>
      <input type="search" placeholder="search email" />
    </div>

    <div class="m-t-16">
      <ul>
        <li v-for="({date, items}, index) in data"
            :key="index"
            class="m-b-16 flex">
          <b class="date">
            {{ date }}
          </b>
          <ul class="m-left-16">
            <li v-for="({email, actions, userId}, index) in items"
                :key="index">
              <h3 class="m-t-0"
                  :class="{'fail-session': actions.some(o => o.status === 'fail'), 'linkedin-outline': !email && userId}">
                {{ email || `id: ${formatUserId(userId)}` }}
              </h3>
              <details class="m-b-16 m-t-8">
                <summary>Details ({{ actions.length }}) {{ email ? formatUserId(userId) : '' }}</summary>
                <ul class="m-left-24 m-b-16 m-t-8">
                  <li v-for="({name, time, status, details}, index) in actions"
                      :key="index"
                      class="m-b-8">
                    <i v-if="status === 'success'">
                      <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7319 0.295798C13.639 0.20207 13.5284 0.127675 13.4065 0.0769067C13.2846 0.026138 13.1539 0 13.0219 0C12.8899 0 12.7592 0.026138 12.6373 0.0769067C12.5155 0.127675 12.4049 0.20207 12.3119 0.295798L4.86192 7.7558L1.73192 4.6158C1.6354 4.52256 1.52146 4.44925 1.3966 4.40004C1.27175 4.35084 1.13843 4.32671 1.00424 4.32903C0.870064 4.33135 0.737655 4.36008 0.614576 4.41357C0.491498 4.46706 0.380161 4.54428 0.286922 4.6408C0.193684 4.73732 0.12037 4.85126 0.0711659 4.97612C0.0219619 5.10097 -0.00216855 5.2343 0.000152918 5.36848C0.00247438 5.50266 0.0312022 5.63507 0.0846957 5.75814C0.138189 5.88122 0.215401 5.99256 0.311922 6.0858L4.15192 9.9258C4.24489 10.0195 4.35549 10.0939 4.47735 10.1447C4.59921 10.1955 4.72991 10.2216 4.86192 10.2216C4.99393 10.2216 5.12464 10.1955 5.2465 10.1447C5.36836 10.0939 5.47896 10.0195 5.57192 9.9258L13.7319 1.7658C13.8334 1.67216 13.9144 1.5585 13.9698 1.432C14.0252 1.30551 14.0539 1.1689 14.0539 1.0308C14.0539 0.892697 14.0252 0.756092 13.9698 0.629592C13.9144 0.503092 13.8334 0.389441 13.7319 0.295798Z" fill="#16B2AB"/>
                      </svg>
                    </i>
                    <i v-if="status === 'fail'">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="#FF0000"/>
                      </svg>
                    </i> &nbsp;

                    <span>
                      {{ time }} &nbsp; {{ name }}
                    </span>
                    <details v-if="details"
                             class="pointer m-left-24 m-t-4">
                      <summary>Details</summary>
                      <span class="m-left-16">{{ details }}</span>
                    </details>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const {state, dispatch, commit} = useStore();
  dispatch('get_auth_tracking');
  const data = computed(() => state.auth.trackingData);

  function formatUserId (str = '') {
    return `${str.slice(0,3)}-${str.slice(3,6)}-${str.slice(6,9)}`;
  }
</script>

<style lang="scss">
  .auth-tracking {
    @media only screen and (min-width: 0) {
      h1 {
        margin-top: 0;
      }
      b {
        font-weight: bold;
      }
      details {
        cursor: pointer;
      }
      h3 {
        margin-bottom: 8px;
        font-size: 1.8rem;
        font-weight: 500;
        position: relative;
        &:before {
          content: '';
          width: 15px;
          height: 15px;
          left: -24px;
          top: 3px;
          display: block;
          border-radius: 50%;
          background-color: #1eb386;
          position: absolute;
        }
        &.fail-session {
          &:before {
            background-color: red;
          }
        }
        &.linkedin-outline {
          color: #304eb1;
        }
      }
      .date {
        padding-right: 24px;
        border-right: 1px solid #888888;
      }
    }
  }
</style>