<template>
  <div class="side-menu h100">
    <img src="../assets/logo.svg" alt="logo" />
    <nav class="m-t-24">
      <router-link :to="`/cpanel/${route}`"
                   v-for="({name, route, emoji}, index) in menu"
                   :key="index">
        {{ emoji }} &nbsp;
        <span>
          {{ name }}
        </span>
      </router-link>
    </nav>
  </div>
</template>

<script setup>
  import {ref} from "vue";

  const menu = ref([
    {name: 'Auth', route: 'auth-tracking', emoji: '🔐'},
    {name: 'Users', route: '', emoji: '👥'},
    {name: 'Prompts', route: 'prompts', emoji: '💭'},
    {name: 'Errors', route: 'errors', emoji: '❌'},
    {name: 'Resumes', route: 'resumes', emoji: '📄'},
    {name: 'Resumes preview', route: 'resumes-preview', emoji: '👀'},
    {name: 'Reports', route: 'reports', emoji: '📊'},
    {name: 'Support', route: 'support', emoji: '🆘'},
  ]);
</script>

<style lang="scss">
  .side-menu {
    @media only screen and (min-width: 0) {
      position: sticky;
      top: 0;
      height: 100vh;
      min-width: 200px;
      padding: 18px 8px 24px 16px;
      background-color: #F6F6F6;
      color: black;
      text-decoration: none;
      font-weight: 600;
      border-right: 2px solid #c4c4dc;
      nav {
        a {
          margin-bottom: 12px;
          padding: 4px 4px 4px 8px;
          display: block;
          font-size: 1.6rem;
          text-decoration: none;
          border: 1px solid #F6F6F6;
          span {
            color: #193887;
            font-weight: 500;
          }
          &.router-link-exact-active {
            background-color: white;
            border-radius: 8px;
            border: 1px solid lightgrey;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
</style>